import axios from "axios";
import router from "@/router";

const BASE_URL = "http://192.168.1.3:9877";
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 50000,
  headers: {
    "Content-Type": "application/json",
  },
});

const handleError = (error) => {
  let currentPath;
  if (error.response) {
    switch (error.response.status) {
      case 401:
        currentPath = router.currentRoute.value.fullPath;
        localStorage.setItem("redirect", currentPath);
        router.push(`/setting`);
        break;
      case 403:
        console.error("Access forbidden");
        break;
      case 404:
        console.error("Resource not found");
        break;
      case 500:
        console.error("Server error");
        break;
      default:
        console.error("An error occurred:", error.response.data);
    }
  } else if (error.request) {
    console.error("No response received:", error.request);
  } else {
    console.error("Error setting up request:", error.message);
  }
  return Promise.reject(error);
};

// 请求拦截器，用于在每个请求中添加 token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // 假设 token 存储在 localStorage 中
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => handleError(error)
);

// GET 请求
export const get = async (endpoint, params = {}, customConfig = {}) => {
  try {
    const response = await axiosInstance.get(endpoint, {
      params,
      ...customConfig,
    });
    return response.data;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const getRaw = async (endpoint, params = {}, customConfig = {}) => {
  try {
    const response = await axiosInstance.get(endpoint, {
      params,
      ...customConfig,
    });
    return response;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

// POST 请求
export const post = async (endpoint, data = {}) => {
  try {
    const response = await axiosInstance.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("POST request error:", error);
    throw error;
  }
};

export const postStream = async (endpoint, body = {}, callback = () => {}) => {
  try {
    let processedLength = 0;
    await axiosInstance({
      method: "post",
      url: endpoint,
      data: body,
      responseType: "text",
      onDownloadProgress: (progressEvent) => {
        const response = progressEvent.event.target.response;
        const newData = response.substr(processedLength);
        processedLength = response.length;

        const lines = newData.split("\n");
        lines.forEach((line) => {
          if (line.trim() === "") return;
          try {
            const item = line.replace(/^data: /, "").trim();
            if (item === "[DONE]") return;
            const parsedLine = JSON.parse(item);
            const { choices } = parsedLine;
            if (choices && choices.length > 0) {
              const { delta } = choices[0];
              if (delta && delta.content) {
                callback(delta.content);
              }
            }
          } catch (error) {
            console.error("Error parsing line:", error);
          }
        });
      },
    });
  } catch (error) {
    handleError(error);
  }
};

// PUT 请求
export const put = async (endpoint, data = {}) => {
  try {
    const response = await axiosInstance.put(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("PUT request error:", error);
    throw error;
  }
};

// DELETE 请求
export const del = async (endpoint) => {
  try {
    const response = await axiosInstance.delete(endpoint);
    return response.data;
  } catch (error) {
    console.error("DELETE request error:", error);
    throw error;
  }
};
