<template>
  <div class="chat-container">
    <div class="messages-container">
      <div v-for="(message, index) in messages" :key="index">
        <div
          class="message-wrapper"
          :class="message.role === 'user' ? 'message-user' : 'message-ai'"
        >
          <div v-if="message.role === 'user'" class="avatar">S</div>
          <div>
            {{ message.content }}
          </div>
        </div>
      </div>
    </div>

    <div class="input-container">
      <EditBox :minRows="2" hint="Start chat ..." @send="send" />
    </div>
  </div>
</template>

<script>
import EditBox from "@/components/EditBox.vue";

export default {
  name: "ChatShow",
  components: {
    EditBox,
  },
  data() {
    return {
      messages: [
        {
          role: "ai",
          content:
            "你好，我是Kimi，一个由深度求索（DeepSeek）公司开发的智能助手。",
        },
        {
          role: "user",
          content:
            "我们修改输入框容器的样,我们修改输入框容器的样,我们修改输入框容器的样,我们修改输入框容器的样,我们修改输入框容器的样,我们修改输入框容器的样,我们修改输入框容器的样我们修改输入框容器的样我们修改输入框容器的样我们修改输入框容器的样我们修改输入框容器的样我们修改输入框容器的样我们修改输入框容器的样,我们修改输入框容器的样我们修改输入框容器的样我们修改输入框容器的样",
        },
        {
          role: "ai",
          content:
            "你好，我是Kimi，一个由深度求索（DeepSeek）公司开发的智能助手。",
        },
        {
          role: "user",
          content: "你好",
        },
        {
          role: "ai",
          content:
            "我们修改输入框容器的样,我们修改输入框容器的样,我们修改输入框容器的样,我们修改输入框容器的样,我们修改输入框容器的样,我们修改输入框容器的样,我们修改输入框容器的样我们修改输入框容器的样我们修改输入框容器的样我们修改输入框容器的样我们修改输入框容器的样我们修改输入框容器的样我们修改输入框容器的样,我们修改输入框容器的样我们修改输入框容器的样我们修改输入框容器的样",
        },
        {
          role: "user",
          content: "你好",
        },
        {
          role: "ai",
          content:
            "你好，我是Kimi，一个由深度求索（DeepSeek）公司开发的智能助手。",
        },
        {
          role: "user",
          content: "你好",
        },
        {
          role: "ai",
          content:
            "你好，我是Kimi，一个由深度求索（DeepSeek）公司开发的智能助手。",
        },
        {
          role: "user",
          content: "你好",
        },
        {
          role: "ai",
          content:
            "你好，我是Kimi，一个由深度求索（DeepSeek）公司开发的智能助手。",
        },
        {
          role: "user",
          content: "你好",
        },
        {
          role: "ai",
          content:
            "你好，我是Kimi，一个由深度求索（DeepSeek）公司开发的智能助手。",
        },
        {
          role: "user",
          content: "你好",
        },
        {
          role: "ai",
          content:
            "你好，我是Kimi，一个由深度求索（DeepSeek）公司开发的智能助手。",
        },
        {
          role: "user",
          content: "你好",
        },
        {
          role: "ai",
          content:
            "你好，我是Kimi，一个由深度求索（DeepSeek）公司开发的智能助手。",
        },
        {
          role: "user",
          content: "你好",
        },
        {
          role: "ai",
          content:
            "你好，我是Kimi，一个由深度求索（DeepSeek）公司开发的智能助手。",
        },
        {
          role: "user",
          content: "你好",
        },
        {
          role: "ai",
          content: "this is the lase line。",
        },
      ],
      inputMessage: "",
    };
  },
  methods: {
    send(message) {
      console.log(message);
    },
  },
};
</script>

<style scoped>
.chat-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
}

.messages-container {
  flex: 1;
  width: 60%;
  margin: 0 auto;
  padding: 20px;
  overflow-y: auto;
  padding-bottom: 100px;
}

.message-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  line-height: 1.5;
}

.avatar {
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  background-color: #8b3dff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.message-ai {
  background-color: #ddd;
  color: #000;
}

.message-user {
  background-color: #32322f;
  color: #fff;
}

.input-container {
  position: fixed;
  width: 60%;
  margin: 0 auto;
  padding: 20px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
}

.input-wrapper {
  position: relative;
  width: 60%;
  margin: 0 auto;
}

.message-input {
  width: 100%;
  min-height: 24px;
  max-height: 200px;
  padding: 12px;
  padding-right: 80px;
  border: none;
  border-radius: 6px;
  background-color: #383838;
  color: #fff;
  resize: none;
  line-height: 1.5;
  overflow-y: auto;
  transition: height 0.1s ease; /* 添加平滑过渡效果 */
}

.input-actions {
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  gap: 8px;
  z-index: 1;
}

.action-btn {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 4px;
}
</style>
