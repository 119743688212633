<template>
  <div class="quote-container">
    <div class="quote-content">
      <div class="parameters-section">
        <!-- Material Section -->
        <div class="parameter-group">
          <div class="parameter-label">
            Material Specification
            <span class="help-icon">?</span>
          </div>
          <div class="material-selection">
            <div>
              <label class="dimension-label">Material</label>
              <div class="material-dropdown">
                <div
                  class="selected-display"
                  @mouseenter="showMaterialMenu = true"
                  @mouseleave="handleMaterialMenuLeave"
                >
                  {{ selectedMaterialName || "---" }}
                </div>

                <div
                  v-if="showMaterialMenu"
                  class="dropdown-menu"
                  @mouseenter="showMaterialMenu = true"
                  @mouseleave="handleMaterialMenuLeave"
                >
                  <div
                    v-for="mat in materials"
                    :key="mat.type"
                    class="menu-item"
                    @mouseenter="hoveredMaterial = mat"
                    @mouseleave="hoveredMaterial = null"
                  >
                    <span>{{ mat.name }}</span>
                    <div v-if="hoveredMaterial === mat" class="submenu">
                      <div
                        v-for="grade in mat.grades"
                        :key="grade"
                        class="submenu-item"
                        @click="selectMaterial(mat.type, grade)"
                      >
                        {{ grade }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="material && materialGrade" class="material-dropdown">
              <div>
                <label class="dimension-label">Grade</label>
                <div class="selected-display">
                  {{ materialGrade }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Dimensions Section -->
        <div class="parameter-group">
          <div class="parameter-label">
            Dimensions
            <span class="help-icon">?</span>
          </div>
          <div class="dimensions-input">
            <div
              v-for="(dim, idx) in dimensions"
              :key="idx"
              class="dimension-item"
            >
              <label class="dimension-label">{{ dim.label }}</label>
              <div class="dimension-control">
                <input
                  type="number"
                  v-model.number="dim.value"
                  :placeholder="dim.default"
                  class="dimension-input"
                  step="1"
                  min="1"
                />
              </div>
            </div>
            <div class="dimension-item">
              <label class="dimension-label">Unit</label>
              <div class="dimension-control">
                <select v-model="unit" class="base-input">
                  <option v-for="u in units" :key="u" :value="u">
                    {{ u }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!-- Quality Requirements -->
        <div class="parameter-group">
          <div class="parameter-label">
            Quality Requirements
            <span class="help-icon">?</span>
          </div>
          <div class="quality-inputs">
            <div class="quality-item">
              <label class="dimension-label">Surface Roughness (Ra)</label>
              <select v-model="surfaceRoughness" class="base-input">
                <option v-for="ra in roughnessOptions" :key="ra" :value="ra">
                  {{ ra }} μm
                </option>
              </select>
            </div>
            <div class="quality-item">
              <label class="dimension-label">Tolerance</label>
              <select v-model="tolerance" class="base-input">
                <option v-for="tol in toleranceOptions" :key="tol" :value="tol">
                  {{ tol }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Surface Treatment -->
        <div class="parameter-group">
          <div class="parameter-label">
            Surface Treatment
            <span class="help-icon">?</span>
          </div>
          <div class="material-selection">
            <div>
              <label class="dimension-label">Treatment Type</label>
              <div class="material-dropdown">
                <select v-model="surfaceTreatment" class="base-input">
                  <option
                    v-for="treatment in treatmentOptions"
                    :key="treatment.type"
                    :value="treatment.type"
                  >
                    {{ treatment.name }}
                  </option>
                </select>
              </div>
            </div>

            <div v-if="selectedTreatment?.colors?.length">
              <label class="dimension-label">Color</label>
              <div class="material-dropdown">
                <select v-model="treatmentColor" class="base-input">
                  <option
                    v-for="color in selectedTreatment.colors"
                    :key="color"
                    :value="color"
                  >
                    {{ color }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!-- Protection Requirements -->
        <div class="parameter-group">
          <div class="parameter-label">
            Protection Requirements
            <span class="help-icon">?</span>
          </div>
          <div class="protection-inputs">
            <div class="protection-options">
              <label
                v-for="protection in protectionOptions"
                :key="protection.type"
                class="protection-option"
              >
                <input
                  type="checkbox"
                  v-model="selectedProtections"
                  :value="protection.type"
                />
                {{ protection.name }}
              </label>
            </div>
          </div>
        </div>

        <!-- Delivery Requirements -->
        <div class="parameter-group">
          <div class="parameter-label">
            Delivery Requirements
            <span class="help-icon">?</span>
          </div>
          <div class="material-selection">
            <div>
              <label class="dimension-label">Delivery Time</label>
              <div class="material-dropdown">
                <select v-model="deliveryTime" class="base-input">
                  <option
                    v-for="option in deliveryTimeOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>

            <div>
              <label class="dimension-label">Shipping Method</label>
              <div class="material-dropdown">
                <select v-model="shippingMethod" class="base-input">
                  <option
                    v-for="method in shippingMethods"
                    :key="method"
                    :value="method"
                  >
                    {{ method }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!-- Quantity Section -->
        <div class="parameter-group">
          <div class="parameter-label">
            Quantity
            <span class="help-icon">?</span>
          </div>
          <div class="quantity-selector">
            <input
              type="number"
              v-model.number="quantity"
              min="1"
              class="base-input"
              step="1"
            />
          </div>
        </div>

        <!-- Manufacturing Process -->
        <div class="parameter-group">
          <div class="parameter-label">
            Manufacturing Process
            <span class="help-icon">?</span>
          </div>
          <div class="process-selection">
            <div
              v-for="process in manufacturingProcesses"
              :key="process.type"
              class="process-option"
              :class="{ active: selectedProcesses.includes(process.type) }"
              @click="toggleProcess(process.type)"
            >
              <input
                type="checkbox"
                :checked="selectedProcesses.includes(process.type)"
                @change="toggleProcess(process.type)"
              />
              <div class="process-info">
                <span class="process-name">{{ process.name }}</span>
                <span class="process-desc">{{ process.description }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="quote-summary">
        <div class="price-display">
          <div class="price-breakdown">
            <div class="base-price">Base Price: {{ formattedBasePrice }}</div>
          </div>
          <div class="total-price">
            <span class="price">Total: {{ formattedTotalPrice }}</span>
          </div>
        </div>
        <button
          class="quote-button"
          @click="submitQuote"
          :disabled="!isQuoteValid"
        >
          {{ quoteButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SimpleQuote",

  data() {
    return {
      material: "",
      materialGrade: "",
      unit: "mm",

      materials: [
        {
          type: "aluminum",
          name: "铝板",
          grades: [
            "铝6061",
            "铝6061-T6",
            "铝2024",
            "铝5052",
            "铝5083",
            "铝6063",
            "铝6082",
            "铝7075",
            "铝7075-T6",
            "铝ADC12(A380)",
          ],
          pricePerUnit: 50,
        },
        {
          type: "copper",
          name: "铜",
          grades: [
            "铜C101(T2)",
            "铜C103(T1)",
            "铜C103(TU2)",
            "铜C110(TU0)",
            "铍铜",
          ],
          pricePerUnit: 70,
        },
        {
          type: "brass",
          name: "黄铜",
          grades: ["黄铜C27400", "黄铜C28000", "黄铜C36000"],
          pricePerUnit: 65,
        },
        {
          type: "bronze",
          name: "青铜",
          grades: ["锡青铜"],
          pricePerUnit: 75,
        },
        {
          type: "steel",
          name: "铁板",
          grades: [
            "钢1018",
            "钢1020",
            "钢1025",
            "钢1045",
            "钢1215",
            "钢4130",
            "钢4140",
            "钢4340",
            "钢5140",
            "钢A36",
          ],
          pricePerUnit: 40,
        },
        {
          type: "stainless",
          name: "不锈钢",
          grades: [
            "不锈钢SUS201",
            "不锈钢SUS303",
            "不锈钢SUS304",
            "不锈钢SUS316",
            "不锈钢SUS316L",
            "不锈钢SUS420",
            "不锈钢SUS430",
            "不锈钢SUS431",
            "不锈钢SUS440C",
            "不锈钢SUS630/17-4PH",
          ],
          pricePerUnit: 60,
        },
        {
          type: "magnesium",
          name: "镁",
          grades: ["镁合金AZ31B", "镁合金AZ91D"],
          pricePerUnit: 80,
        },
        {
          type: "titanium",
          name: "钛",
          grades: ["钛合金TA1", "钛合金TA2", "钛合金TC4/Ti-6Al-4V"],
          pricePerUnit: 120,
        },
      ],
      dimensions: [
        { label: "Length", value: null, default: 100 },
        { label: "Width", value: null, default: 100 },
        { label: "Height", value: null, default: 100 },
      ],
      units: ["mm", "cm", "inch"],
      quantity: 1,
      showMaterialMenu: false,
      hoveredMaterial: null,
      surfaceRoughness: "1.6",
      roughnessOptions: ["0.2", "0.4", "0.8", "1.6", "3.2", "6.3"],
      tolerance: "±0.05",
      toleranceOptions: ["±0.01", "±0.02", "±0.05", "±0.1", "±0.2"],
      deliveryTime: "normal",
      deliveryTimeOptions: [
        {
          value: "urgent",
          label: "Urgent (1-2 days)",
          multiplier: 1.5,
          isDefault: false,
        },
        { value: "fast", label: "3-5 days", multiplier: 1.2, isDefault: false },
        {
          value: "normal",
          label: "6-10 days",
          multiplier: 1.0,
          isDefault: true,
        },
        {
          value: "half_month",
          label: "Half month",
          multiplier: 0.9,
          isDefault: false,
        },
        {
          value: "one_month",
          label: "One month",
          multiplier: 0.8,
          isDefault: false,
        },
        {
          value: "two_months",
          label: "Two months",
          multiplier: 0.7,
          isDefault: false,
        },
      ],
      shippingMethod: "Standard",
      shippingMethods: ["Express", "Standard", "Economy"],
      qualityFactors: {
        roughness: {
          0.2: 2.0,
          0.4: 1.8,
          0.8: 1.5,
          1.6: 1.2,
          3.2: 1.0,
          6.3: 0.8,
        },
        tolerance: {
          "±0.01": 2.0,
          "±0.02": 1.7,
          "±0.05": 1.4,
          "±0.1": 1.2,
          "±0.2": 1.0,
        },
      },
      surfaceTreatment: "",
      treatmentColor: "",
      treatmentOptions: [
        {
          type: "none",
          name: "No Treatment",
          priceMultiplier: 1.0,
        },
        {
          type: "anodizing",
          name: "Anodizing",
          colors: ["Natural", "Black", "Gold", "Blue", "Red"],
          priceMultiplier: 1.4,
        },
        {
          type: "plating",
          name: "Electroplating",
          colors: ["Chrome", "Nickel", "Zinc", "Gold"],
          priceMultiplier: 1.5,
        },
        {
          type: "sandblasting",
          name: "Sandblasting",
          priceMultiplier: 1.2,
        },
        {
          type: "powder_coating",
          name: "Powder Coating",
          colors: ["Black", "White", "Gray", "Custom"],
          priceMultiplier: 1.3,
        },
        {
          type: "polishing",
          name: "Polishing",
          priceMultiplier: 1.15,
        },
      ],
      selectedProtections: [],
      protectionOptions: [
        {
          type: "rust_proof",
          name: "Rust Proof",
          priceAdd: 5,
        },
        {
          type: "moisture_proof",
          name: "Moisture Proof",
          priceAdd: 3,
        },
        {
          type: "anti_static",
          name: "Anti-static",
          priceAdd: 4,
        },
        {
          type: "vacuum_pack",
          name: "Vacuum Packaging",
          priceAdd: 6,
        },
      ],
      manufacturingProcesses: [
        {
          type: "cnc_milling",
          name: "CNC Milling",
          description: "For complex 3D shapes and precision parts",
          priceMultiplier: 1.0,
        },
        {
          type: "cnc_turning",
          name: "CNC Turning",
          description: "For cylindrical parts and high precision",
          priceMultiplier: 0.9,
        },
        {
          type: "cnc_swiss",
          name: "CNC Swiss Machining",
          description: "For small precision parts (0.5mm-21mm)",
          priceMultiplier: 1.2,
        },
      ],
      selectedProcesses: [],
      certificationRequirements: [
        {
          type: "first_article",
          name: "First Article Inspection",
          price: 150,
        },
        {
          type: "ppap",
          name: "PPAP Documentation",
          price: 300,
        },
        {
          type: "material_cert",
          name: "Material Certification",
          price: 50,
        },
      ],
      selectedCertifications: [],
      applicationTypes: [
        "Prototype",
        "Low Volume Production",
        "Mass Production",
      ],
      selectedApplication: "Prototype",
      inspectionRequirements: {
        type: "standard", // standard, cmm, custom
        customRequirements: "",
      },
    };
  },

  computed: {
    currentMaterialGrades() {
      const material = this.materials.find((m) => m.type === this.material);
      return material ? material.grades : [];
    },

    formattedBasePrice() {
      return this.formatPrice(this.calculateBasePrice());
    },
    formattedQualityPrice() {
      return this.formatPrice(this.calculateQualityPrice());
    },
    formattedTreatmentPrice() {
      return this.formatPrice(this.calculateTreatmentPrice());
    },
    formattedProtectionPrice() {
      return this.formatPrice(this.calculateProtectionPrice());
    },
    formattedDeliveryPrice() {
      return this.formatPrice(this.calculateDeliveryPrice());
    },
    formattedTotalPrice() {
      return this.formatPrice(this.calculateTotalPrice());
    },
    isQuoteValid() {
      return this.material && this.materialGrade && this.quantity > 0;
    },
    quoteButtonText() {
      return this.isQuoteValid ? "Get Instant Quote" : "Please Select Material";
    },

    selectedMaterialName() {
      if (!this.material) return "";
      const material = this.materials.find((m) => m.type === this.material);
      return material ? material.name : "";
    },

    selectedMaterialDisplay() {
      if (!this.material || !this.materialGrade) return "";
      const material = this.materials.find((m) => m.type === this.material);
      return material ? `${material.name} - ${this.materialGrade}` : "";
    },

    selectedTreatment() {
      return this.treatmentOptions.find(
        (t) => t.type === this.surfaceTreatment
      );
    },
  },

  methods: {
    formatPrice(amount) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(amount);
    },

    calculateBasePrice() {
      if (!this.material) return 0;
      const material = this.materials.find((m) => m.type === this.material);
      if (!material) return 0;

      const volume = this.dimensions.reduce((acc, dim) => {
        const value = dim.value || dim.default;
        return acc * value;
      }, 1);

      return material.pricePerUnit * volume * 0.001 * this.quantity;
    },

    calculateQualityPrice() {
      const basePrice = this.calculateBasePrice();
      const roughnessFactor =
        this.qualityFactors.roughness[this.surfaceRoughness] || 1;
      const toleranceFactor =
        this.qualityFactors.tolerance[this.tolerance] || 1;
      return basePrice * (roughnessFactor + toleranceFactor - 2);
    },

    calculateTreatmentPrice() {
      if (!this.selectedTreatment) return 0;
      const basePrice = this.calculateBasePrice();
      return basePrice * (this.selectedTreatment.priceMultiplier - 1);
    },

    calculateProtectionPrice() {
      return (
        this.selectedProtections.reduce((total, protectionType) => {
          const protection = this.protectionOptions.find(
            (p) => p.type === protectionType
          );
          return total + (protection?.priceAdd || 0);
        }, 0) * this.quantity
      );
    },

    calculateDeliveryPrice() {
      const basePrice = this.calculateBasePrice();
      const deliveryOption = this.deliveryTimeOptions.find(
        (opt) => opt.value === this.deliveryTime
      );
      return basePrice * ((deliveryOption?.multiplier || 1) - 1);
    },

    calculateTotalPrice() {
      return (
        this.calculateBasePrice() +
        this.calculateQualityPrice() +
        this.calculateTreatmentPrice() +
        this.calculateProtectionPrice() +
        this.calculateDeliveryPrice()
      );
    },

    submitQuote() {
      console.log("Submitting quote:", {
        material: this.material,
        grade: this.materialGrade,
        dimensions: this.dimensions,
        unit: this.unit,
        quantity: this.quantity,
        price: this.formattedTotalPrice,
      });
    },

    updateDimension(index, newValue) {
      if (newValue >= 1) {
        this.dimensions[index].value = newValue;
      }
    },

    selectMaterial(type, grade) {
      this.material = type;
      this.materialGrade = grade;
      this.showMaterialMenu = false;
      this.hoveredMaterial = null;
    },

    hideMaterialMenu() {
      this.showMaterialMenu = false;
      this.hoveredMaterial = null;
    },

    handleMaterialMenuLeave() {
      setTimeout(() => {
        if (!this.$el.querySelector(".material-dropdown:hover")) {
          this.showMaterialMenu = false;
          this.hoveredMaterial = null;
        }
      }, 100);
    },

    toggleProcess(processType) {
      const index = this.selectedProcesses.indexOf(processType);
      if (index === -1) {
        this.selectedProcesses.push(processType);
      } else {
        this.selectedProcesses.splice(index, 1);
      }
      this.updatePrice();
    },
  },
};
</script>

<style scoped>
.quote-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.service-tabs {
  display: flex;
  gap: 2px;
  margin-bottom: 20px;
  background: #f5f5f5;
  padding: 4px;
  border-radius: 8px;
}

.tab {
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 6px;
}

.tab.active {
  background: white;
  color: #1890ff;
}

.quote-content {
  display: flex;
  gap: 24px;
}

.parameters-section {
  flex: 1;
}

.parameter-group {
  margin-bottom: 24px;
}

.parameter-label {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  font-weight: 500;
}

.help-icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #eee;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
}

.material-options,
.delivery-options {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.material-card,
.delivery-card {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  min-width: 120px;
  text-align: center;
}

.material-card.active,
.delivery-card.active {
  border-color: #1890ff;
  background: #e6f7ff;
}

.dimensions-input {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dimensions-input input {
  width: 120px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.unit-select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.quantity-selector {
  display: flex;
  align-items: center;
}

.quantity-selector input {
  width: 100px;
  text-align: center;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.quote-button-section {
  width: 300px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: fit-content;
}

.price-display {
  text-align: center;
  margin-bottom: 16px;
}

.price {
  font-size: 24px;
  font-weight: bold;
  color: #f5222d;
}

.quote-button {
  width: 100%;
  padding: 12px;
  background: #f5222d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.quote-button:hover {
  background: #cf1322;
}

.material-selection {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.material-selection select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 150px;
}

.quality-options {
  display: flex;
  gap: 16px;
}

.tolerance-input,
.surface-finish {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.file-upload {
  border: 2px dashed #ddd;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.file-requirements {
  margin-top: 8px;
  font-size: 12px;
  color: #666;
}

.dimension-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 120px;
}

.dimension-label {
  font-size: 14px;
  color: #666;
  margin-right: 1rem;
}

.dimension-control {
  display: flex;
  align-items: center;
  gap: 4px;
}

.dimension-input {
  width: 80px;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
}

.dimension-input::placeholder {
  color: #999;
}

.material-dropdown {
  position: relative;
  width: 250px;
}

.selected-display {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.menu-item {
  padding: 8px 12px;
  position: relative;
  cursor: pointer;
}

.menu-item:hover {
  background: #f5f5f5;
}

.submenu {
  position: absolute;
  left: 100%;
  top: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  min-width: 150px;
}

.submenu-item {
  padding: 8px 12px;
  cursor: pointer;
}

.submenu-item:hover {
  background: #f5f5f5;
}

.quality-inputs,
.delivery-inputs {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.quality-item,
.delivery-item {
  flex: 1;
  min-width: 200px;
}

.protection-options {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.protection-option {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.protection-option input[type="checkbox"] {
  width: 16px;
  height: 16px;
}

.base-input {
  padding: 8px 36px 8px 18px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
}

.price-breakdown {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
  font-size: 14px;
  color: #666;
}

.total-price {
  font-size: 24px;
  font-weight: bold;
  color: #f5222d;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #eee;
}

.quote-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.file-drop-zone {
  border: 2px dashed #ddd;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}

.file-drop-zone:hover {
  border-color: #1890ff;
  background: #f0f8ff;
}

.file-list {
  margin-top: 16px;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 8px;
}

.process-selection {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.process-option {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
}

.process-option.active {
  border-color: #1890ff;
  background: #e6f7ff;
}
</style>
