<template>
  <div class="textarea-wrapper">
    <textarea
      ref="textarea"
      v-model="inputText"
      :style="{ height: textareaHeight }"
      @input="adjustHeight"
      @keydown.enter.prevent="onEnter"
      :placeholder="hint"
      class="input-textarea"
    ></textarea>
    <button
      @click="sendMessage"
      class="send-button"
      :disabled="!inputText.trim()"
      :class="{ 'send-button-active': inputText.trim() }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="send-icon"
      >
        <line x1="22" y1="2" x2="11" y2="13"></line>
        <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "EditBox",
  props: {
    hint: {
      type: String,
      default: "Type your message here...",
    },
    minRows: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      inputText: "",
      textareaHeight: "auto",
    };
  },
  computed: {
    minHeight() {
      return `${this.minRows * 1.5}em`;
    },
  },
  methods: {
    adjustHeight() {
      const textarea = this.$refs.textarea;
      textarea.style.height = "auto";
      const computedHeight = Math.max(
        parseFloat(this.minHeight),
        textarea.scrollHeight
      );
      textarea.style.height = `${computedHeight}px`;
      this.textareaHeight = textarea.style.height;
    },
    onEnter(e) {
      if (!e.shiftKey) {
        this.sendMessage();
      }
    },
    sendMessage() {
      if (this.inputText.trim()) {
        this.$emit("send", this.inputText);
        this.adjustHeight();
        this.inputText = "";
      }
    },
  },
  mounted() {
    this.adjustHeight();
  },
};
</script>

<style scoped>
.textarea-wrapper {
  position: relative;
  background-color: #fff;
  border-radius: 12px;
  transition: box-shadow 0.3s ease, border 0.3s ease;
  border: 1px solid #e0e0e0;
}

.textarea-wrapper:focus-within {
  border-color: #ccc; /* 聚焦时改变边框颜色 */
}

.input-textarea {
  width: 100%;
  min-height: v-bind("minHeight");
  padding: 16px;
  font-size: 16px;
  line-height: 1.5;
  border: none;
  resize: none;
  overflow-y: auto; /* 修改：允许垂直滚动 */
  background-color: transparent;
  color: #333;
  box-sizing: border-box; /* 添加这一行 */
}

.input-textarea:focus {
  outline: none;
}

.input-textarea::placeholder {
  color: #999;
}

.send-button {
  position: absolute;
  right: 12px;
  bottom: 12px;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1; /* 添加：确保按钮在文本上方 */
}

.send-button:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* 移除 translateY，保留缩放效果 */
}

.send-button:active {
  transform: scale(0.95); /* 移除 translateY，保留按下效果 */
}

.send-button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
  opacity: 0.7;
}

.send-button-active {
  animation: pulse 1.5s infinite;
}

.send-icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.send-button:hover .send-icon {
  transform: translateX(2px);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}

@media (max-width: 600px) {
  .input-textarea {
    font-size: 14px;
  }

  .send-button {
    width: 40px;
    height: 40px;
  }
}
</style>
