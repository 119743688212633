<template>
  <div class="section">
    <h1>CNC Turning Page</h1>
  </div>
</template>

<script>
export default {
  name: "CncTurningPage",
};
</script>
