<template>
  <div class="section">
    <div class="title">
      <h1>Rapid Prototyping</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "RapidPrototype",
  data() {
    return {};
  },
};
</script>

<style scoped>
.section {
  background-color: var(--section-bg-reverse-color);
}
</style>
