const state = {
  services: [
    {
      id: 1,
      name: "CNC Turning",
      features: [
        {
          title: "Tight Tolerance",
          content:
            'High precision machining with tolerances down to +/-.0008" (0.02mm), following ISO 2768 standards.',
        },
        {
          title: "Diverse Materials",
          content:
            "Expertise in machining 50+ materials including various metals (stainless steel, aluminum, titanium) and engineering plastics.",
        },
        {
          title: "Fast Turnaround",
          content:
            "Instant online quotes and efficient production workflow for quick delivery times.",
        },
        {
          title: "Size Range",
          content:
            "Capable of machining parts from Ø0.5mm up to Ø431mm diameter and 990mm length.",
        },
        {
          title: "Quality Assurance",
          content:
            "Comprehensive QC system with PPAP, FMEA support and AS9100 certification for aerospace parts.",
        },
        {
          title: "Advanced Equipment",
          content:
            "High-precision Japanese Mazak CNC lathes with maximum speed 1700RPM for superior surface finish.",
        },
      ],
      image:
        "https://www.custommechparts.com/Data/custommechparts/template/en/cap/1/js.jpg",
      more: "/service/cnc-turning",
    },
    {
      id: 3,
      name: "CNC Swiss Machining",
      features: [
        {
          title: "Small Precision",
          content:
            "Specialized in small diameter parts from 0.5mm to 21mm with critical straightness and concentricity tolerances.",
        },
        {
          title: "Complex Features",
          content:
            "Advanced capability for complex profiles including thin walls, deep cuts, and intricate features with multi-axis machining.",
        },
        {
          title: "High Volume",
          content:
            "Highly competitive for large volume production with efficient simultaneous operations and reduced secondary processing.",
        },
        {
          title: "Multi Process",
          content:
            "Combined turning and milling capabilities with live tooling and sub-spindles for complete part manufacturing.",
        },
        {
          title: "13 Years Experience",
          content:
            "Extensive expertise in medical instruments, surgical tools, optical equipment, military and defense components.",
        },
        {
          title: "Diverse Materials",
          content:
            "Expert handling of various materials including stainless steel, carbon steel, aluminum, brass, copper and plastics.",
        },
      ],
      image:
        "https://www.custommechparts.com/Data/custommechparts/template/en/cap/3/js.jpg",
      more: "/service/swiss",
    },
    {
      id: 4,
      name: "Machined Castings",
      features: [
        {
          title: "Complete Solution",
          content:
            "Integration of casting and precision CNC machining for optimal quality and cost efficiency.",
        },
        {
          title: "Ready Stock",
          content:
            "100% complete-to-print parts in inventory available for immediate shipping.",
        },
        {
          title: "Precision Standard",
          content:
            "Following ISO8062/GB/T6414 CT5-CT6 standards for precise dimensional control and superior surface finish.",
        },
        {
          title: "Multiple Finishes",
          content:
            "Comprehensive surface treatment options including wet paint, powder coat, anodizing, black oxide, and various plating types.",
        },
        {
          title: "Value Added",
          content:
            "Installation of components and assembly services available to reduce your total manufacturing costs.",
        },
        {
          title: "Material Expertise",
          content:
            "Specialized in various stainless steels (304, 316, 17-4 PH) and other high-performance alloys with corrosion resistance.",
        },
      ],
      image:
        "https://www.custommechparts.com/Data/custommechparts/template/en/cap/4/js.jpg",
      more: "/service/machined-casting",
    },
    {
      id: 5,
      name: "Precision EDM Machining",
      features: [
        {
          title: "Ultra Precision",
          content:
            'Achieve extreme tight tolerances down to +/- .0001" (0.0025mm) for complex and precision parts.',
        },
        {
          title: "Complete EDM",
          content:
            "Comprehensive EDM solutions including Sink EDM, Wire EDM and Small Hole EDM for all machining needs.",
        },
        {
          title: "Hard Materials",
          content:
            "Specialized in machining extremely hard and conductive materials including Inconel, titanium, and high-strength steels.",
        },
        {
          title: "4-Hour Quote",
          content:
            "Fast response with online quotes provided in less than 4 hours for efficient project planning.",
        },
        {
          title: "Complex Shapes",
          content:
            "Expert in producing complex shapes with small internal radii that cannot be machined by conventional methods.",
        },
        {
          title: "One-Stop Solution",
          content:
            "Integrated services combining EDM with turning, milling, grinding, heat treating, and surface coating capabilities.",
        },
      ],
      image:
        "https://www.custommechparts.com/Data/custommechparts/template/en/cap/5/js.jpg",
      more: "/service/edm",
    },
    {
      id: 2,
      name: "CNC Milling",
      features: [
        { title: "Demo title 1", content: "Demo content 1" },
        { title: "Demo title 2", content: "Demo content 2" },
      ],
      image:
        "https://www.custommechparts.com/Data/custommechparts/template/en/cap/2/js.jpg",
      more: "/service/cnc-milling",
    },
  ],
};

const getters = {
  services: (state) => state.services,
};

const serviceModule = {
  state,
  getters,
};

export default serviceModule;
