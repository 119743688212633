<!-- src/views/Products.vue -->
<template>
  <div>
    <ProductList />
  </div>
</template>

<script>
import ProductList from "@/components/ProductList.vue";
import * as api from "@/utils/api";

export default {
  name: "ProductsPage",
  components: {
    ProductList,
  },
  async mounted() {
    const res = await api.get("api/page/visit?page=products");
    console.log(res);
  },
};
</script>
