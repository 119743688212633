<template>
  <div class="section">
    <h1>EDM Page</h1>
  </div>
</template>
  
  <script>
export default {
  name: "EdmPage",
};
</script>
  