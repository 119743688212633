<template>
  <div id="app" class="container">
    <div v-if="showHeader" class="header">
      <AppHeader />
    </div>
    <div class="body">
      <RouterView />
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";

export default {
  name: "App",
  components: {
    AppHeader,
  },
  computed: {
    showHeader() {
      return !(this.$route.meta.hideHeader ?? false);
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.container {
  display: flex;
  flex-direction: column;
}

.header,
.footer {
  text-align: center;
  flex-shrink: 0;
}

.body {
  flex: 1;
  overflow-y: auto;
}

.logo-color {
  color: #e06d3c;
}

.hide {
  display: none;
}
</style>
