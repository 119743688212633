import { createRouter, createWebHashHistory } from "vue-router";
import AboutPage from "@/views/AboutPage.vue";
import HomePage from "@/views/HomePage.vue";
import ProductsPage from "@/views/ProductsPage.vue";
import ProductsDetail from "@/components/ProductDetail.vue";
import CncTurningPage from "@/pages/service/CncTurningPage.vue";
import CncMillingPage from "@/pages/service/CncMillingPage.vue";
import EdmPage from "@/pages/service/EdmPage.vue";
import MachinedCastingPage from "@/pages/service/MachinedCasting.vue";
import SwissPage from "@/pages/service/SwissPage.vue";
import ChatPage from "@/pages/ChatPage.vue";
import BlankPage from "@/pages/BlankPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/blank",
    name: "Blank",
    component: BlankPage,
  },
  {
    path: "/product",
    name: "Products",
    component: ProductsPage,
  },
  {
    path: "/product/:id",
    name: "ProductDetail",
    component: ProductsDetail,
  },
  {
    path: "/about",
    name: "About",
    component: AboutPage,
  },
  {
    path: "/service/cnc-turning",
    name: "CncTurning",
    component: CncTurningPage,
  },
  {
    path: "/service/cnc-milling",
    name: "CncMilling",
    component: CncMillingPage,
  },
  {
    path: "/service/edm",
    name: "Edm",
    component: EdmPage,
  },
  {
    path: "/service/machined-casting",
    name: "MachinedCasting",
    component: MachinedCastingPage,
  },
  {
    path: "/service/swiss",
    name: "Swiss",
    component: SwissPage,
  },
  {
    path: "/chat",
    name: "Chat",
    component: ChatPage,
    meta: { hideHeader: true },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
