<template>
  <div class="section">
    <div class="title">
      <h1>Why Choose Us</h1>
    </div>

    <div class="content">
      <div class="images">
        <img
          class="image"
          src="https://assets.vention.io/images/home/benefits/slider-1.png"
          alt="why-choose-us"
        />
      </div>

      <div class="reasons">
        <div class="reason" v-for="reason in reasons" :key="reason.title">
          <div class="reason-title">{{ reason.title }}</div>
          <div class="reason-content">{{ reason.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhyChooseUs",
  data() {
    return {
      reasons: [
        {
          title: "14 Years Expertise",
          content:
            "Expert in precision parts manufacturing since 2010, with proven track record serving global customers in aerospace, medical and automotive industries.",
        },
        {
          title: "Manufacturing Hub",
          content:
            "Located in Dongguan, China's manufacturing center, enabling competitive pricing and fast delivery through established supply chain networks.",
        },
        {
          title: "One-Stop Solution",
          content:
            "Complete manufacturing capabilities combining CNC machining, casting, forging, and sheet metal work to provide the most cost-effective solutions.",
        },
        {
          title: "Quality Assured",
          content:
            "ISO9001 certified with digital tracking system ensuring consistent quality and on-time delivery for every order.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.images {
  flex: 5;
}

.image {
  width: 100%;
}

.reasons {
  flex: 4;
  padding-left: 24px;
}

.reason {
  margin-bottom: 48px;
}

.reason-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 12px;
}

.reason-content {
  font-size: 1rem;
  color: #4a4a4a;
  line-height: 1.5;
}

.reason-title span {
  color: #4169e1;
}
</style>
