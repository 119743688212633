<template>
  <div class="section">
    <div class="content-wrapper">
      <div class="contact-info">
        <div class="info-item">
          <h3>Email</h3>
          <a href="mailto:your.email@example.com">your.email@example.com</a>
        </div>

        <div class="info-item">
          <h3>Location</h3>
          <p>您的地址</p>
        </div>
      </div>

      <div class="site-navigation">
        <div class="nav-section">
          <h3>样品展示</h3>
          <ul>
            <li><router-link to="/blank">样品</router-link></li>
          </ul>
        </div>

        <div class="nav-section">
          <h3>Services</h3>
          <ul>
            <li><router-link to="/blank">CNC Turning</router-link></li>
            <li>
              <router-link to="/blank">CNC Swiss Machining</router-link>
            </li>
            <li><router-link to="/blank">Machined Castings</router-link></li>
            <li>
              <router-link to="/blank">Precision EDM Machining</router-link>
            </li>
            <li><router-link to="/blank">CNC Milling</router-link></li>
          </ul>
        </div>

        <div class="nav-section">
          <h3>资源中心</h3>
          <ul>
            <li><router-link to="/blank">资源列表</router-link></li>
            <li><router-link to="/blank">博客</router-link></li>
          </ul>
        </div>

        <div class="nav-section contact-us">
          <h3>About Us</h3>
          <ul>
            <li><router-link to="/about">Company Introduction</router-link></li>
            <li><router-link to="/blank">Recruitment</router-link></li>
          </ul>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from "@/components/AppFooter.vue";
export default {
  name: "ContactUs",
  components: {
    AppFooter,
  },
  data() {
    return {
      googleMapUrl: `https://www.google.com/maps/embed/v1/place?key=YOUR_API_KEY&q=您的地址`,
    };
  },
};
</script>

<style scoped>
.section {
  background-color: var(--section-bg-reverse-color);
}

.content-wrapper {
  display: flex;
  width: 100%;
}

.contact-info {
  flex: 0 0 400px;
}

.info-item {
  margin-bottom: 2rem;
}

.site-navigation {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5rem;
}

.nav-section {
  margin-bottom: 1rem;
}

.nav-section ul {
  list-style: none;
  padding: 0;
}

.nav-section li {
  margin: 0.5rem 0;
}

.nav-section a {
  text-decoration: none;
  color: inherit;
}

.nav-section a:hover {
  color: #007bff;
}

.contact-us {
  flex-basis: 100%;
}

/* 响应式布局 */
@media (max-width: 1200px) {
  .content-wrapper {
    justify-content: center;
    flex-wrap: wrap;
  }

  .contact-info {
    flex: 0 0 100%;
    max-width: 800px;
  }

  .site-navigation {
    flex: 0 0 100%;
    max-width: 800px;
  }
}

@media (max-width: 600px) {
  .nav-section {
    flex: 0 0 100%;
  }
}
</style>
